<template>
  <div class="container">
    <h4 class="mt-4 text-center">Изменение курса у студента</h4>

    <div class="col-md-3">
      <div class="input-group mb-3 mt-4">
        <input type="number" class="form-control" placeholder="Баркод" v-model="barcode">
        <button class="btn btn-primary" type="button" @click="getStudentDataAndCourses" :disabled="search">
          <span v-if="search" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{search?'Поиск':'Найти'}}
        </button>
      </div>
    </div>

    <div v-if="isSearch">
      <div class="mt-4">
        <h5 class="mt-2">Данные о студенте</h5>
        <div>Баркод: {{studentData.barcode}}</div>
        <div>ФИО: {{studentData.last_name+' '+studentData.first_name}}</div>
        <div>Группа: {{studentData.student_group_name}}</div>
        <div>Курс: {{studentData.study_course}}</div>
        <div>ОП: {{studentData.education_program_name}}</div>
      </div>

      <div class="mt-3" v-if="studentCourses.length">
        <h5 class="mt-2">Курсы студента</h5>
        <div class="mb-2" v-for="(item, index) in studentCourses" :key="index">
          <a :href="'https://moodle.uib.kz/course/view.php?id='+ item.mdl_course_id" target="_blank">
            {{item.course_name}}
          </a>
        </div>
      </div>

      <div class="row mt-4" v-if="studentCourses.length">
        <h5 class="mt-2 mb-2">Изменение курса</h5>

        <div class="col-md-5">
          <label for="oldCourse">Из курса</label>
          <select class="form-select" id="oldCourse" v-model="oldCourse">
            <option v-for="(item, index) in studentCourses"
                    :value="item.course_id"
                    :key="index">
              {{item.course_name}} {{item.lastname}} ({{item.mdl_course_id}})
            </option>
          </select>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-5">
          <label for="newCourse">На курс</label>
          <select class="form-select" id="newCourse" v-model="newCourse">
            <option v-for="(item, index) in allCourses"
                    :value="item.course_id"
                    :key="index">
              {{item.course_name}} {{item.lastname}} ({{item.mdl_course_id}})
            </option>
          </select>
        </div>
      </div>

      <div class="text-center mt-3" v-if="oldCourse&&newCourse">
        <button class="btn btn-primary" @click="changeCourse" :disabled="edit">
          <span v-if="edit" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
          {{edit?'Изменение':'Изменить'}}
        </button>
      </div>
    </div>

  </div>
</template>

<script>
  import httpClient from "@/services/http.service"

  export default {
    name: 'ChangeStudentCourse',
    data() {
      return {
        search: false,
        isSearch: false,
        barcode: null,
        studentData: {},
        studentCourses: [],
        allCourses: [],
        oldCourse: 0,
        newCourse: 0,
        edit: false
      }
    },
    methods: {
      async getStudentDataAndCourses() {
        this.oldCourse = 0
        this.newCourse = 0

        this.search = true
        this.isSearch = false
        try {
          const {status, data} = await httpClient.get(`education_program/education-courses/get-student-data-and-courses?barcode=${this.barcode}`)

          if (status === 200) {
            if (data.success) {
              this.studentData = data.data.studentData
              this.studentCourses = data.data.studentCourses
              this.allCourses = data.data.allCourses
              this.isSearch = true
            }
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.search = false
      },
      async changeCourse() {
        this.edit = true
        try {
          const form = {
            student_id: this.studentData.student_id,
            old_course: this.oldCourse,
            new_course: this.newCourse
          }

          const {status, data} = await httpClient.post(`education_program/education-courses/change-education-course`, form)

          if (status === 200 && data == 'success') {
            await this.getStudentDataAndCourses()
            this.newCourse = 0
            this.$message({text: 'Курс успешно изменен'})
          } else {
            this.$error({text: 'При изменении произошла ошибка'})
          }
        } catch (e) {
          this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
        }
        this.edit = false
      }

    },
  }
</script>

<style scoped>

</style>